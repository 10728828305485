@keyframes slideInUp {
  from {
      transform: translateY(100%);
  }
  to {
      transform: translateY(0);
  }
}

@keyframes slideOutDown {
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(100%);
  }
}

.comment-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align the view to the bottom */
  z-index: 1000; /* Ensure it appears above other elements */
}

.comment-view-container {
  background: white;
  width: 100%;
  max-width: 600px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 75vh; /* Maximum height of 75% of the screen height */
  min-height: 50vh; /* Minimum height of 50% of the screen height */
  display: flex;
  flex-direction: column; /* Ensure children are stacked vertically */
  animation: slideInUp 0.3s forwards;
}

.comment-view-container.slide-out {
  animation: slideOutDown 0.3s forwards;
}

.messages-container {
  flex: 1; /* Take up remaining space within .comment-view-container */
  overflow-y: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: fit-content;
}

.writer-message {
  background: #f1f1f1;
  align-self: flex-start; /* Align writer messages to the left */
}

.user-message {
  background: #FF6F61;
  color: white;
  align-self: flex-end; /* Align user messages to the right */
}

.input-container {
  display: flex;
  align-items: center;
}

.input-container input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.input-container button {
  padding: 10px 20px;
  background: #FF6F61;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
