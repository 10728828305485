.update-view-container {
    max-width: 80%; /* Ensure the container doesn't exceed 80% of the screen width */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add some padding */
    box-sizing: border-box; /* Include padding and border in the width calculation */
    padding-top: 80px;
}

.update-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Use flexbox for horizontal alignment */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
}

.update-item {
    margin-bottom: 15px;
    width: 100%; /* Ensure it uses full width of the parent */
    max-width: 600px; /* Ensure a max width if needed */
}

.blog-item-view, .message-item-view {
    width: 100%; /* Ensure both components use full width of their container */
    box-sizing: border-box; /* Include padding and border in the width calculation */
}
