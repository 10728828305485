.blog-item-view {
  width: 100%; /* Ensures it uses full width of its parent */
  max-width: 600px; /* Ensure a max width if needed */
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding and border in the width calculation */
}

@media (max-width: 600px) {
  .blog-item-view {
      width: 90%; /* Responsive width on smaller screens */
  }
}

.content-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.character-info {
  flex: 1;
}

.character-name {
  font-weight: bold;
  display: block;
}

.post-date {
  color: #777;
}

.images-container {
  padding: 10px;
  position: relative;
}

.post-content {
  padding: 10px;
}

.post-actions {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.like-button, .comment-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #007bff;
}

.like-button svg, .comment-button svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.like-button:hover, .comment-button:hover {
  opacity: 0.8;
}

.follow-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left: auto;
}

.follow-button svg {
  width: 24px;
  height: 24px;
  fill: #007bff;
}

.follow-button:hover {
  opacity: 0.8;
}
