.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-right: 2rem; /* Add padding to the right */
  background-color: #FF6F61; /* Light scarlet background color */
  color: #fff;
  position: fixed; /* Fix the header at the top */
  width: 100%; /* Ensure the header spans the full width */
  top: 0; /* Position it at the top */
  left: 0; /* Align it to the left edge */
  z-index: 1000; /* Ensure it stays above other content */
}

.app-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

.app-icon {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%; /* Make the icon itself circular */
}


.header-container h1 {
  margin: 0;
}

.nav {
  display: flex;
  margin-right: 4rem; /* Add space between the nav and the right edge */
}

.nav a, .logout-button {
  color: #fff;
  margin: 0 1rem; /* Adjust spacing between the links */
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav a:hover, .logout-button:hover {
  text-decoration: underline;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
}

.logout-button:hover {
  color: #f0e68c; /* Optional: Change color on hover */
}

.nav-icon {
  margin-right: 0.5rem; /* Space between the icon and text */
}
