.chat-view-container {
    position: relative; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-top: 80px;
    text-align: center; /* Center text and inline elements */
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.writer-name {
    font-size: 24px;
    margin-bottom: 20px;
}

.writer-chat-button {
    background-color: #FF6F61;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    text-align: center; /* Center text inside button */
    margin-top: 15px;
}

.writer-chat-button:hover {
    opacity: 0.8;
}
