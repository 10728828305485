.signin-container {
  margin-top: 70px; /* Adjust this value based on the height of your header */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 70px); /* Adjust based on header height */
}

.signin-form {
  background-color: white;
  padding: 30px; /* Add padding to the form */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Ensure the form is centered and has a max width */
}

.signin-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signin-form button {
  width: 100%;
  padding: 10px;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signin-form button:hover {
  background-color: #e5533b;
}

.signin-form .signup-link {
  margin-top: 10px;
  text-align: center;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
