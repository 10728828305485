.explore-container {
    padding: 20px;
    padding-top: 80px; /* Add top padding to account for the fixed header height */
  }
  
  .search-bar {
    
    position: fixed;
  top: 80px; /* Adjust based on header height */
  left: 20px; /* Adjust based on desired left position */
  right: 20px; /* Add right margin */
  background-color: white;
  z-index: 1000; /* Ensure it stays above the list */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  }
  
  .writer-list {
    top: 80px;
    margin: 0;
    
    list-style: none;
    padding: 0;
    padding-top: 40px;
    
  }
  
  .writer-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-radius: 12px; /* Rounded corners */
    background-color: #fff; /* Ensure the background color is white */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
    margin-bottom: 10px; /* Add space between items */
  }
  
  .writer-profile-image-explore {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .writer-info-explore {
    display: flex;
    flex-direction: column;
  }
  
  .writer-name-explore {
    font-weight: bold;
  }
  
  .writer-age-explore {
    color: #777;
    font-size: 0.9em; /* Slightly smaller font size */
  }