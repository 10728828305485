.main-container {
  padding-top: 70px; /* Adjust this value based on the height of your header */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-block {
  width: 100%;
  max-width: 600px; /* Fixed width */
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .content-block {
    width: 90%; /* Make it responsive */
  }
}

  
  .content-header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Divider line between header and content */
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover; /* Ensure image covers the circle */
    margin-right: 10px; /* Space between profile image and text */
  }
  
  .writer-info {
    flex: 1;
  }
  
  .writer-name {
    font-weight: bold;
    display: block;
  }
  
  .post-date {
    color: #777;
  }
  
  .images-container {
    padding: 10px;
    position: relative; /* Needed for slider positioning */
  }
  
  .post-content {
    padding: 10px;
  }
  
  h2 {
    font-size: 1.5em;
    margin-top: 0;
  }
  
  p {
    margin: 0;
    color: #333;
  }
  
  .loading {
    text-align: center;
    padding: 20px;
    color: #666;
  }


  .follow-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    margin-left: auto; /* Pushes the button to the right */
  }
  
  .follow-button svg {
    width: 24px;
    height: 24px;
    fill: #007bff; /* Adjust color as needed */
  }
  
  .follow-button:hover {
    opacity: 0.8;
  }

  .post-actions {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border-top: 1px solid #ddd; /* Divider line between post content and actions */
  }
  
  .like-button, .comment-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #007bff; /* Adjust color as needed */
  }
  
  .like-button svg, .comment-button svg {
    width: 24px;
    height: 24px;
    margin-right: 5px; /* Space between icon and text */
  }
  
  .like-button:hover, .comment-button:hover {
    opacity: 0.8;
  }
  
  