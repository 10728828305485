.chat-messages-view {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%); /* Center horizontally and position off-screen */
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 100%;
    max-width: 80%;
    min-height: 50vh; /* Minimum height */
    max-height: 75vh; /* Maximum height */
    overflow: hidden;
    z-index: 1000;
    padding: 0; /* Remove padding to use flexbox spacing */
    box-sizing: border-box;
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    display: flex;
    flex-direction: column;
}

.chat-messages-view.show {
    opacity: 1;
    transform: translateX(-50%) translateY(0); /* Slide in from the bottom */
}

.chat-messages-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px; /* Add padding */
    background: #fff; /* Background color for header */
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
}

.close-button img {
    width: 30px;
    height: 30px;
}

.chat-messages-content {
    flex: 1; /* Take up available space */
    overflow-y: auto;
    padding: 20px; /* Add padding */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add space between messages */
}

.chat-message {
    padding: 10px;
    border-radius: 8px;
    width: fit-content;
    max-width: 80%; /* Prevent messages from stretching too wide */
    align-self: flex-start; /* Default alignment */
}

.user-message {
    background: #FF6F61;
    color: white;
    align-self: flex-end; /* Align user messages to the right */
    text-align: right; /* Align text inside user message to the right */
}

.writer-message {
    background: #f1f1f1;
    align-self: flex-start; /* Align writer messages to the left */
    text-align: left; /* Align text inside writer message to the left */
}

.chat-messages-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
}

.chat-messages-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.chat-messages-input button {
    background-color: #FF6F61;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
}
