.image-slider-container {
    width: 100%;
    max-width: 600px; /* Adjust based on your layout */
    margin: 0 auto;
    position: relative; /* Ensure relative positioning for any absolute positioning inside */
  }
  
  .slick-slide {
    display: flex; /* Ensure images are centered */
    justify-content: center;
    align-items: center;
  }
  
  .post-image {
    width: 100%; /* Ensure image takes full width of the slider */
    height: 300px; /* Fixed height for the images */
    object-fit: cover; /* Cover the container and clip any overflow */
    border-radius: 8px; /* Optional: rounded corners for the images */
    display: block; /* Ensure images are displayed as blocks */
  }
  