.user-profile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    padding-top: 80px;
}

.profile-container {
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure children are centered */
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

h2 {
    margin-bottom: 10px;
}

p {
    margin-bottom: 20px;
}

.logout-button {
    background: #FF6F61;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    align-self: center; /* Center the button horizontally */
}

.logout-button:hover {
    background: #0056b3;
}
