.image-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.back-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 1em;
}

.back-button:hover {
    background: #0056b3;
}

.image-container {
    position: relative;
    max-width: 80%;
    margin: 0 auto;
}

.viewed-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tag-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.0);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.tag-button img {
    width: 24px;
    height: 24px;
}

.tag-button:hover {
    background: rgba(255, 255, 255, 1);
}
