.message-item-view {
    width: 100%; /* Ensures it uses full width of its parent */
    max-width: 600px; /* Ensure a max width if needed */
    background-color: white;
    padding: 20px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Include padding and border in the width calculation */
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Vertically center items */
}

@media (max-width: 600px) {
    .message-item-view {
        width: 90%; /* Responsive width on smaller screens */
    }
}

.writer-profile-image-message {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.message-info {
    max-width: 100%; /* Allow the content to use the available width */
    display: flex;
    flex-direction: column; /* Align child elements vertically */
}

.writer-name {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: 1em;
}

.message-text {
    margin-bottom: 5px;
}

.message-time {
    color: #888;
    font-size: 0.85em;
}
