/* Ensure the container allows scrolling if content overflows */
.writer-details-container {
    position: relative; /* Changed from fixed to relative */
    margin: 0 auto; /* Center horizontally */
    padding: 20px;
    width: 100%;
    max-width: 80%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Allow vertical scrolling */
    max-height: 100vh; /* Make sure it doesn’t exceed viewport height */
    margin-top: 100px;
}

.cover-image {
    width: 100%;
    height: auto;
    max-height: 450px;
    object-fit: cover;
    border-bottom: 2px solid #ccc;
}

.writer-details {
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding-top: 20px;
    margin-top: -75px;
    position: relative; /* Ensure buttons are positioned correctly */
}

.writer-profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: -30px;
    z-index: 1;
}

.writer-details-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 45px;
}

.writer-bio-age {
    margin-top: 20px;
}

.writer-occupation {
    font-size: 1.2em;
    color: #333;
    margin-top: -2px;
}

.writer-name {
    font-size: 2em;
    margin: 10px 0;
}

.writer-bio {
    margin: 10px 0;
    color: #666;
}

.photos-section {
    margin-top: 20px;
}

.stories-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stories-list {
    width: 100%;
    max-width: 600px;
}

.action-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 80px;
}

.follow-button, .chat-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px; /* Space between buttons */
}

.follow-button img, .chat-button img {
    width: 24px; /* Adjust size as needed */
    height: 24px;
}

@media (max-width: 600px) {
    .writer-details {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 0;
    }

    .writer-profile-image {
        width: 100px;
        height: 100px;
        margin-right: 0;
        margin-top: -50px;
    }

    .writer-details-info {
        margin-top: 20px;
        align-items: center;
    }

    .writer-bio-age {
        margin-top: 20px;
        align-items: center;
    }

    .writer-name {
        font-size: 1.5em;
    }

    .writer-age {
        font-size: 1em;
    }

    .action-buttons {
        justify-content: center;
    }
}
